.inst-address {
  &__Image {
    max-width: 12rem;
    display: block;
    margin-bottom: 2rem;
  }

  ul {
    li {
      padding-top: 2rem;
      display: flex;
      flex-direction: column;

      * {
        font-size: 1rem;
        font-weight: normal;
      }
    }
  }

  &__Title {
    font-size: 1.125rem;
  }
}
