.institution-page-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-emerge);
  z-index: 1;

  &__Image {
    height: 642px;
    width: 100%;
    max-width: 1200px;
    display: block;
    position: absolute;
  }

  &__Teaser {
    $padding: 1.5rem;
    margin-top: 366px;
    padding: $padding $padding 5rem $padding;
    display: flex;
    max-width: 37rem;
    z-index: 2;
    background-color: white;

    &__Text-Container {
      flex: 3;
    }

    &__Spacer {
      flex: 2;
      height: 260px;
      align-self: flex-end;
    }

    &-Container {
      z-index: 2;
    }

    &__Title {
      font-size: calc(32rem / 16);
    }
  }

  &__Introduction {
    &-Container {
      display: flex;
      margin-top: 120px;
      column-gap: 100px;
      row-gap: 1.5rem;
      padding-bottom: 100px;

      @media only screen and (max-width: 800px) {
        flex-direction: column-reverse;
        padding-bottom: 2rem;
      }
    }

    &__Image {
      height: 500px;
      min-width: min(360px, 100%);
      display: block;
    }

    &__Text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 300px;
      @media screen and (max-width: 400px) {
        min-width: unset;
      }

      &__Title-Top {
        margin-bottom: 1rem;
        font-size: calc(18rem / 16);
      }

      &__Title {
        margin-bottom: 1.5rem;
        font-size: 2rem;
      }
    }
  }
}

:host {
  display: block;
}
