tremaze-information-tag-chip-list {
  display: block;
}

.subheader {
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: #fca020;
  color: white;
  width: 100%;
  text-align: center;
  font-size: large;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
}

.subheader-button {
  margin-left: 16px;
  white-space: nowrap;

  @media screen and (max-width: 600px) {
    margin-top: 8px;
  }
}

.page__Section {
  margin-bottom: 8rem;

  &__Heading {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 650px) {
  .page__Section {
    margin-bottom: 4rem;

    &__Heading {
      margin-bottom: 1.5rem;
    }
  }
}

#was-wir-bieten {
  padding-bottom: 4rem;
  padding-top: 2rem;
  border-radius: 1rem;
}

#partner {
  padding-top: 2rem;
  border-radius: 1rem 1rem 0 0;
}

#partner + * {
  border-radius: 0 0 1rem 1rem;
  padding-bottom: 4rem;
}

#partner,
#partner + *,
#was-wir-bieten {
  background-color: var(--bg-emerge);
}
