:host {
  $color: var(--primary);
  $vertical-padding: 0.25em;
  padding: $vertical-padding 1em;
  border-radius: 2 * $vertical-padding + 1em;
  background: transparent;
  border: 1px solid $color;
  outline: none;
  display: inline-block;
  color: $color;
  font-weight: bold;
  line-height: 1.5em;
  font-size: 0.875rem;

  &:not(.disable-selection) {
    cursor: pointer;

    &:focus,
    &:hover {
      background-color: var(--primary-sl);
    }

    &:focus-visible {
      outline-offset: 2px;
      outline: var(--accent-d) dashed 2px;
    }
  }

  &.chip-active {
    background: $color;
    color: var(--primary-fg);

    &:not(.disable-selection) {
      &:focus,
      &:hover {
        background-color: var(--primary-d);
      }
    }

    &:focus-visible {
      outline-offset: 2px;
      outline: var(--accent-d) dashed 2px;
    }
  }
}
