:host {
  display: contents;
}

.event-schedule {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.event-schedule__Head {
  display: flex;
  justify-content: center;
  padding: 0 1.5rem 2rem 1.5rem;
  align-items: center;
  text-align: center;
}
@media only screen and (max-width: 759px) {
  .event-schedule__Head {
    justify-content: space-between;
  }
}
.event-schedule__Head__Week {
  font-size: 1.125rem;
  font-weight: bold;
}
.event-schedule__Head > *:not(:last-child) {
  margin-right: 16px;
}
.event-schedule__Body {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: scroll;
}
.event-schedule__Body::-webkit-scrollbar {
  width: 0;
}
.event-schedule__Body__Item {
  height: 100%;
  flex: 1;
  min-width: 6.25rem;
  min-height: 5rem;
  box-sizing: border-box;
}
.event-schedule__Body__Item__Head {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid lightgray;
}
.event-schedule__Body__Item__Title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.25rem;
}
.event-schedule__Body__Item__Subtitle {
  font-size: 1.125rem;
}
.event-schedule__Body__Item__Body {
  display: grid;
  padding: 1rem 0.25rem;
  grid-row-gap: 0.5rem;
  margin: 0;
  grid-auto-columns: 100%;
}
.event-schedule__Body__Item__Body a {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  max-width: 100%;
}
.event-schedule__Body__Item__Body a:focus {
  outline: 2px solid black;
}
.event-schedule__Body__Item__Body a:focus-visible {
  outline-offset: 2px;
  outline: var(--accent-d) dashed 2px;
}
.event-schedule__Body__Item__Body > *:not(:focus) {
  max-width: 100%;
  display: block;
}
.event-schedule__Body__Item:hover .event-schedule__Add-Button {
  opacity: 1;
  transform: rotateX(0deg);
  tab-index: 0;
  pointer-events: auto;
}
.event-schedule__Add-Button {
  width: 100%;
  padding: 15px;
  border: 1px solid lightgray;
  background-color: transparent;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  font-size: 20px;
  opacity: 0;
  transform: rotateX(90deg);
  transition: all 0.3s ease;
  cursor: pointer;
  tab-index: -1;
  pointer-events: none;
}