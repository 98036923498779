.ng-invalid.ng-touched.tremaze-checkbox-container {
  input:not(:checked) + .tremaze-checkbox__Indicator {
    border-color: var(--warn);
  }
}

.tremaze-checkbox {
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0;
  align-items: center;
  cursor: pointer;

  &.disabled {
    .tremaze-checkbox__Indicator {
      border-color: var(--disabled);
    }
  }

  &__Indicator {
    &-Container {
      display: flex;
      align-items: center;
    }

    transition: background-color 100ms ease;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-color: transparent;
    border: calc(1rem / 16) solid black;
    display: inline-block;
    position: relative;

    &:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      content: '';
      position: absolute;
      opacity: 0;
      transition: opacity 300ms ease;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked + .tremaze-checkbox__Indicator {
      background: var(--primary);

      &:after {
        opacity: 1;
      }
    }
  }

  &:not(.disabled) {
    input:not(:checked):focus + .tremaze-checkbox__Indicator,
    &:hover input:not(:checked) + .tremaze-checkbox__Indicator {
      background-color: var(--primary-sl);
    }
  }
}
