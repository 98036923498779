.footer {
  &__Bottom {
    &-Container {
      background-color: var(--primary);
      padding: 2rem 1rem;
      color: var(--primary-fg);
      position: relative;

      a {
        color: inherit;
        text-decoration: none;
        font-weight: bold;
        display: block;
        margin-bottom: 1rem;

        &:hover,
        &:focus {
          text-decoration: underline;
        }
      }

      .page__Section {
        display: flex;
        row-gap: 3rem;
        justify-content: space-between;
      }
    }

    &__Logo-Link__List {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      @media screen and (max-width: 400px) {
        flex-direction: column;
        align-items: center;
      }

      li:not(:last-child) {
        margin-right: 4rem;
        @media screen and (max-width: 400px) {
          margin-right: 0;
        }
      }
    }
  }

  &__Top {
    &-Container {
      h6 {
        font-size: 1rem;
        font-weight: normal;
      }

      img {
        height: 4rem;
        width: auto;
        max-width: 100%;
        object-fit: contain;
      }

      .page__Section {
        padding: 7.5rem 0;
        margin: 0 3rem;
        align-items: flex-start !important;
        border-top: 1px solid rgba(0, 0, 0, 0.23);
        max-width: calc(100% - 6rem);

        @media only screen and (max-width: 870px) {
          padding: 96px 0;
          flex-direction: column;

          > *:not(:last-child) {
            margin-bottom: 96px;
          }
        }
      }
    }
  }

  tremaze-image {
    div {
      display: contents;
    }

    img {
      max-height: 100px;
      min-height: 70px;
      object-fit: contain;
    }
  }

  .page__Section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    ul:first-of-type {
      min-width: 14rem;
      width: 0;
    }
  }
}

#version-string {
  position: absolute;
  bottom: 0.25rem;
  right: 0.5rem;
}
