.tremaze-form-field {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  > textarea {
    box-sizing: border-box;
    resize: vertical;
    flex-grow: 1;
    flex-shrink: 0;
  }

  &__Suffix {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    height: 3em;
    display: flex;
    align-items: center;

    .tremaze-button--Icon {
      height: 3em;
      width: 3em;
      color: var(--accent);
      box-shadow: none;

      &:hover,
      &:focus {
        background: transparent;
      }
    }
  }

  > *:first-child {
    z-index: 2;
  }

  &__Err {
    z-index: 1;
  }
}
