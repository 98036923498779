:host {
  display: contents;
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 899;

  padding: 9rem 2rem 0 3.5rem;

  font-size: 1.6rem;
  font-weight: bold;

  ul {
    li {
      a {
        padding: 1rem 0;
        display: block;
        text-decoration: none;
        color: black;
      }
    }
  }
}
