
      :host {
        display: flex;
        flex-wrap: wrap;
        column-gap: 10vw;
        row-gap: 3rem;
        justify-content: center;
      }

      tremaze-opening-times-week-display {
        display: block;
        flex: 1;
        min-width: min(100%, 440px);
        max-width: 100%;
      }
    