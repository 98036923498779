:host {
  overflow: hidden;
}

.carousel {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  overflow: hidden;
  outline: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &:focus-visible {
    outline-offset: 2px;
    outline: var(--accent-d) dashed 2px;
  }

  &__List {
    &-Wrapper {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      overflow: hidden;
      flex: 1;
      position: relative;

      &__Inner {
        height: 100%;
        flex: 1;
        overflow: hidden;
        min-width: 0;


        &--Center-Mode {
          overflow: visible;
        }
      }
    }

    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-auto-flow: column;
  }

  &__Slide {
    height: 100%;
    touch-action: pan-y !important;
    cursor: grab;

    img {
      pointer-events: none !important;
    }
  }

  &--Show-All {
    .carousel__List {
      grid-auto-flow: row;
      grid-template-rows: none !important;
      grid-template-columns: repeat(2, minmax(auto, 100%));
    }

    .carousel__Slide {
      cursor: default;

      img {
        pointer-events: unset !important;
      }
    }
  }

  &__Arrow {
    z-index: 1;

    &--Prev {
      margin-left: 1rem;
    }

    &--Next {
      margin-right: 1rem;
    }
  }

  @media only screen and (max-width: 650px) {
    &__Arrow {
      position: absolute;

      &--Prev {
        margin-left: 0;
        left: 1rem;
      }

      &--Next {
        margin-right: 0;
        right: 1rem;
      }
    }
  }

  &__Indicators {
    display: flex;
    min-width: 100%;
    justify-content: center;
    gap: 0.75rem;

    &-Container {
      margin-top: 3rem;
    }

    &__Button {
      &-Container {
        display: flex;
        align-items: center;
      }

      $h: 0.75rem;

      height: $h;
      width: $h;
      border-radius: 50%;
      box-sizing: border-box;
      border: 1px solid var(--accent);
      padding: 0;
      background-color: transparent;
      cursor: pointer;
      transition: border 0.1s ease;
      outline: none;

      &:focus,
      &:hover {
        &:not(.carousel__Indicators__Button--Active) {
          border-width: calc(#{$h} / 3);
        }
      }

      &--Active {
        border-width: calc(#{$h} / 2);
      }

      &:focus-visible {
        outline-offset: 2px;
        outline: var(--accent-d) dashed 2px;
      }
    }
  }
}
