:host {
  display: contents;
}

.event-schedule {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__Head {
    display: flex;
    justify-content: center;
    padding: 0 1.5rem 2rem 1.5rem;
    align-items: center;
    text-align: center;

    @media only screen and (max-width: 759px) {
      justify-content: space-between;
    }

    &__Week {
      font-size: 1.125rem;
      font-weight: bold;
    }

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__Body {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    &__Item {
      height: 100%;
      flex: 1;
      min-width: calc(100rem / 16);
      min-height: 5rem;

      box-sizing: border-box;

      &__Head {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;

        padding: 5px;

        border-bottom: 1px solid lightgray;
      }

      &__Title {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.25rem;
      }

      &__Subtitle {
        font-size: 1.125rem;
      }

      &__Body {
        display: grid;
        padding: 1rem 0.25rem;
        grid-row-gap: 0.5rem;
        margin: 0;
        grid-auto-columns: 100%;

        a {
          text-decoration: none;
          display: inline-block;
          width: 100%;
          max-width: 100%;

          &:focus {
            outline: 2px solid black;
          }

          &:focus-visible {
            outline-offset: 2px;
            outline: var(--accent-d) dashed 2px;
          }
        }

        > *:not(:focus) {
          max-width: 100%;
          display: block;
        }
      }

      &:hover {
        .event-schedule__Add-Button {
          opacity: 1;
          transform: rotateX(0deg);
          tab-index: 0;
          pointer-events: auto;
        }
      }
    }
  }

  &__Add-Button {
    width: 100%;
    padding: 15px;
    border: 1px solid lightgray;
    background-color: transparent;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    font-size: 20px;
    opacity: 0;
    transform: rotateX(90deg);
    transition: all 0.3s ease;
    cursor: pointer;
    tab-index: -1;
    pointer-events: none;
  }
}
