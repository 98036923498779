ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.day-list {
  &__Item {
    $border: 1px solid var(--border);
    border-bottom: $border;

    &:first-child {
      border-top: $border;
    }

    &__Head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: none;
      border: none;
      outline: none;
      width: 100%;
      padding: 1rem 2rem;
      cursor: pointer;

      &:focus,
      &:hover {
        background-color: var(--primary-sl);
      }

      &__Title {
        font-size: 1.125em;
        color: var(--primary);
      }
    }

    &__Body {
      overflow: hidden;
      display: flex;
      padding: 1rem 2rem;
      justify-content: flex-start;
      box-sizing: border-box;
    }
  }
}

.category-list {
  display: inline-block;

  &__Item {
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }

    &__Title {
      font-size: 1.125em;
      font-weight: bold;
    }
  }
}
