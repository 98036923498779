.contact-form-card {
  --textbox-mh: 7rem;
  background: #ececec;
  padding: 4rem;
  border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  max-width: 100%;

  @media only screen and (max-width: 600px) {
    padding: 2rem 1rem;
    border-radius: 0;
  }

  > * {
    flex: 0 1 100%;
    min-width: min(15rem, 100%);
  }

  &__Headline {
    text-align: center;
    margin-bottom: 2rem;
  }

  &__Name,
  &__Email {
    flex: 1 !important;
  }

  &__Message {
    min-height: var(--textbox-mh);
  }

  &__Button-Container {
    display: flex;
    justify-content: center;
    padding-top: 2rem;
  }
}
