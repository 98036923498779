
      .grid {
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(
          auto-fit,
          minmax(min(100%, max(50%, 20rem)), 1fr)
        );
        grid-row-gap: 5rem;
      }

      .grid-Container {
        padding: 0 3rem;
      }
    