article {
  height: 22.2rem;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: var(--card-r);
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.17);
  overflow: hidden;

  &__Head {
    flex: 1;
    overflow: hidden;
  }

  &__Body {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__Title {
      margin-top: 0;
      font-size: 1.25rem;
    }

    &__Desc {
      flex: 1;
      overflow: hidden;
      margin: 0;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        right: 0;
        height: 35%;
        background-image: linear-gradient(rgba(255, 255, 255, 0.001), white);
      }
    }

    &__Tags {
      height: 27px;
    }
  }
}
