tremaze-information-card {
  .card {
    height: 100%;
  }
}

.information-card-container {
  height: 100%;
  box-sizing: border-box;
  display: block;
  text-decoration: none;
  color: inherit;

  &:focus-visible {
    outline-offset: 2px;
    outline: var(--accent-d) dashed 2px;
  }
}
