.tremaze-chip-list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;

  &--dense {
    tremaze-chip {
      font-size: 0.75rem;
    }
  }
}
