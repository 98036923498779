.icon-with-text {
  display: flex;
  flex-wrap: nowrap;
  padding: 1rem;

  &--Center-Horizontally {
    justify-content: center;
  }

  @media only screen and (max-width: 750px) {
    flex-direction: column;
    align-items: center;

    * {
      text-align: center;
    }
  }

  &__Icon {
    min-width: 9rem;
    display: block !important;
    overflow: hidden;
    max-height: 9rem;
  }

  &__Text {
    padding-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    word-break: break-word;

    @media only screen and (max-width: 750px) {
      padding-left: 0;
    }

    &__Title {
      margin-bottom: 1rem;
    }

    &__Text {
      margin: 0 0 0.5rem 0;

      > *:first-child {
        margin-top: 0;
      }
    }
  }
}
