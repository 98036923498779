:host {
  display: contents;
}

.page {
  &__Head {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 900;
  }

  padding-top: 80px;
}
