
          img {
            object-fit: cover;
            object-position: center;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
          }
    
          :host {
            display: contents;
          }
    
          .tremaze-image__Wrapper {
            height: 100%;
            width: 100%;
          }
    
          .tremaze-image__Wrapper--Use-Aspect-Ratio {
            height: auto;
            position: relative;
          }
    
          .tremaze-image__Wrapper--Use-Aspect-Ratio > img {
            position: absolute;
          }
    
          .tremaze-image__Wrapper--Use-Circular-Image {
            border-radius: 100%;
            overflow: hidden;
          }
        